<template lang="pug">
    .page-header


</template>

<script>
export default {
    name: '',
    props: {
        company: {
            type: Object,
            default() {
                return {
                    name: '',
                    id: '0'
                };
            }
        }
    }
};
</script>