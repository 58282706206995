import { render, staticRenderFns } from "./texts.vue?vue&type=template&id=38da8a68&scoped=true&lang=pug&"
import script from "./texts.vue?vue&type=script&lang=js&"
export * from "./texts.vue?vue&type=script&lang=js&"
import style0 from "./texts.vue?vue&type=style&index=0&id=38da8a68&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38da8a68",
  null
  
)

export default component.exports