<template lang="pug">
    .page-pending
        .page-header
            .inner.mod-inner
                .title {{company.name || '暂无'}}
        .page-content
            .inner.mod-inner
                .title 任务列表
                router-link.time(:to="'/remain-time/' + id")
                    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><path d="M7 0C3.133 0 0 3.133 0 7s3.133 7 7 7 7-3.133 7-7-3.133-7-7-7zm0 12.645A5.644 5.644 0 011.355 7 5.644 5.644 0 017 1.355 5.644 5.644 0 0112.645 7 5.644 5.644 0 017 12.645zm1.744-2.947L6.348 7.957a.34.34 0 01-.138-.274V3.048a.34.34 0 01.338-.338h.904a.34.34 0 01.338.338v4L9.676 8.42c.152.11.183.322.073.474l-.53.731a.341.341 0 01-.475.073z" fill="currentColor" fill-rule="nonzero"/></svg>
                    | 工时记录
                .filter
                    strong 筛选：
                    span.type(:class="{'current': stage === 0 }", @click="chageStage(0)") 全部任务({{tasksDataTotal}})
                    span.type(:class="{'current': stage === 1 }", @click="chageStage(1)") 需求确认中({{filterTaskStatistics(1)}})
                    span.type(:class="{'current': stage === 2 }", @click="chageStage(2)") 执行阶段({{filterTaskStatistics(2)}})
                    span.type(:class="{'current': stage === 3 }", @click="chageStage(3)") 交付阶段({{filterTaskStatistics(3)}})
                    span.type(:class="{'current': stage === 4 }", @click="chageStage(4)") 已完成({{filterTaskStatistics(4)}})
                .tasks
                    table
                        tr
                            th 任务名称
                            th 任务阶段
                            th 需求提出日期
                            th 消耗工时
                            th 查看任务
                        tr(v-for="item in tasks", :key="item.id")
                            td {{item.title}}
                            td {{item.stage | filterStage}}
                            td {{item.ticket.ticket_create_date}}
                            td {{item.ticket.hours_of_work}}
                            td
                                router-link.link(:to="'/' + item.id + '/' + (filterStage(item.stage))")
                                    <svg width="21" height="14" viewBox="0 0 21 14" xmlns="http://www.w3.org/2000/svg"><path d="M10.5 2.917c-.386.006-.77.067-1.139.182.17.3.262.639.264.984a2.042 2.042 0 01-2.042 2.042 2.02 2.02 0 01-.984-.264A4.073 4.073 0 1010.5 2.917zm10.373 3.55C18.896 2.61 14.982 0 10.5 0 6.018 0 2.103 2.612.127 6.468c-.17.335-.17.73 0 1.064C2.104 11.39 6.018 14 10.5 14c4.482 0 8.397-2.612 10.373-6.468.17-.335.17-.73 0-1.064zM10.5 12.25c-3.597 0-6.894-2.005-8.675-5.25C3.606 3.755 6.903 1.75 10.5 1.75c3.597 0 6.894 2.005 8.675 5.25-1.78 3.245-5.078 5.25-8.675 5.25z" fill="currentColor" fill-rule="nonzero"/></svg>
                    el-pagination(
                        layout="prev, pager, next"
                        :total="tasksTotal"
                        background
                        @current-change="sizeChange"
                    )
</template>

<script>
import { Message } from 'element-ui';
import Api from '../../common/api';

export default {
    name: 'Index',
    data() {
        return {
            stage: 0,
            page: 1,
            id: '',
            tasks: [],
            tasksTotal: 0,
            tasksDataTotal: 0,
            taskStatistics: [],
            company: {},
        };
    },
    filters: {
        filterStage(num) {
            const STAGE = [0, '需求确认中', '执行阶段', '交付阶段', '已完成'];
            return STAGE[num];
        }
    },
    created() {
        this.init();
    },
    methods:{
        filterStage(num) {
            const STAGE = [0, 'pending', 'executing', 'confirming', 'finished'];
            return STAGE[num];
        },
        init() {
            const { id } = this.$route.params;
            if (id) {
                this.id = id;
                const loading = this.$loading({
                    text: '获取信息中...',
                });
                Api.get(`/api/companies/${id}`).then(data => {
                    this.company = data;
                });
                Api.get(`/api/companies/${id}/taskStatistics`).then(data => {
                    this.taskStatistics = data;
                    this.tasksDataTotal = 0;
                    data.forEach(item => this.tasksDataTotal += item.num);
                });
                Api.get(`/api/companies/${id}/tasks`, { stage: this.stage, page: this.page }).then(data => {
                    this.tasks = data.data;
                    this.tasksTotal = data.total;
                    setTimeout(() => {
                        loading.close();
                    }, 500);
                }).catch(() => {
                    loading.close();
                });
            } else {
                Message({
                    message: '地址错误，请联系管理员！',
                    type: 'error'
                });
            }
        },
        chageStage(num) {
            this.stage = num;
            this.init();
        },
        sizeChange(num) {
            this.page = num;
            this.init();
        },
        filterTaskStatistics(num) {
            if (this.taskStatistics.length) {
                return this.taskStatistics.filter(item => item.stage === num)[0].num;
            }
        }
    }
};
</script>

<style lang="sass" scoped>
    .page-content
        padding-top: 108px
        .inner
            position: relative
            .time
                position: absolute
                right: 0
                top: 0
                border: 1px solid #CDCDCD
                width: 145px
                height: 41px
                border-radius: 2px
                font-size: 14px
                color: #696D74
                text-decoration: none
                text-align: center
                line-height: 40px
                &:hover
                    color: #204ECF
                    border-color: #204ECF
                svg
                    display: inline-block
                    vertical-align: middle
                    margin-top: -2px
                    margin-right: 8px
        .title
            font-size: 24px
            color: #0E0E0E
            margin-bottom: 16px
        .filter
            background: #FFFFFF
            border: 1px solid #DCDCDC
            box-shadow: 0 2px 4px 0 rgba(194,194,194,0.50)
            border-radius: 2px
            padding: 15px 30px
            font-size: 12px
            color: #696D74
            margin-bottom: 16px
            .type
                margin-left: 28px
                cursor: pointer
            .current
                color: #204ECF
        .tasks
            .el-pagination
                display: flex
                padding-top: 25px
                justify-content: center
        table
            width: 100%
            background: #FFFFFF
            border: 1px solid #DCDCDC
            box-shadow: 0 2px 4px 0 rgba(194,194,194,0.50)
            border-radius: 2px
            tr:hover
                background-color: #EDEDED
            th
                height: 67px
                background: #EDEDED
                border-radius: 2px
                text-align: center
                font-size: 16px
                color: #0E0E0E
                font-weight: normal
            td
                text-align: center
                height: 60px
                border-bottom: 1px solid #DADADA
                .link
                    color: #AEAEAE
                    &:hover
                        color: #204ECF

</style>