<template lang="pug">
    el-dropdown(:showTimeout="0", placement="bottom")
        span
            <svg class="icon" width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M11.82 5.808l-11 5.5v-11z" fill="currentColor" fill-rule="evenodd"/></svg>
            | 已有素材
        el-dropdown-menu(slot="dropdown")
            template(v-for="(item,i) in pictureTypes")
                template(v-if="item.id === 3")
                    template(v-for="(pics,j) in item.pictures")
                            el-dropdown-item(v-if="pics.attachment")
                                a.link(:href="pics.attachment.pub_url", download)
                                    <svg class="icon" width="11" height="14" viewBox="0 0 11 14" xmlns="http://www.w3.org/2000/svg"><path d="M10.596 2.68L8.193.384A1.409 1.409 0 007.222 0H1.375C.615.003 0 .59 0 1.315v11.373C0 13.412.616 14 1.375 14h8.25c.76 0 1.375-.588 1.375-1.312v-9.08c0-.347-.146-.683-.404-.929zm-1.083.822h-2.18v-2.08l2.18 2.08zm-8.138 9.186V1.315h4.583v2.843c0 .364.307.656.688.656h2.979v7.874h-8.25zm.917-1.312h6.416v-3.5l-.673-.642a.356.356 0 00-.487 0L5.042 9.626 3.91 8.546a.356.356 0 00-.487 0l-1.131 1.08v1.75zm1.375-6.562c-.76 0-1.375.588-1.375 1.313 0 .724.616 1.312 1.375 1.312s1.375-.588 1.375-1.312c0-.725-.616-1.313-1.375-1.313z" fill="currentColor" fill-rule="nonzero"/></svg>
                                    | 图片素材 {{j + 1}}
                template(v-if="item.id === 4")
                    template(v-for="(pics,j) in item.pictures")
                            el-dropdown-item(v-if="pics.attachment")
                                a.link(:href="pics.attachment.pub_url", download)
                                    <svg class="icon" width="11" height="15" viewBox="0 0 11 15" xmlns="http://www.w3.org/2000/svg"><path d="M10.596 2.795L8.193.402A1.378 1.378 0 007.222 0H1.375A1.377 1.377 0 000 1.372v11.865c0 .756.616 1.37 1.375 1.37h8.25c.76 0 1.375-.614 1.375-1.37V3.765c0-.362-.146-.713-.404-.97zm-1.083.859h-2.18v-2.17l2.18 2.17zm-8.138 9.583V1.372h4.583v2.966c0 .38.307.685.688.685h2.979v8.214h-8.25zM8.542 9.14c-.35-.343-1.346-.248-1.845-.186-.492-.3-.822-.713-1.054-1.32.112-.46.29-1.158.155-1.598-.12-.747-1.083-.673-1.22-.168-.126.46-.012 1.098.2 1.914-.286.682-.713 1.597-1.014 2.122-.573.294-1.346.747-1.46 1.318-.095.45.744 1.574 2.18-.89.64-.211 1.34-.47 1.958-.573.542.29 1.175.485 1.599.485.73 0 .802-.805.501-1.104zm-5.675 2.219c.147-.391.702-.842.871-.998-.544.864-.87 1.018-.87.998zM5.205 5.92c.212 0 .192.916.052 1.164-.127-.396-.124-1.164-.052-1.164zm-.699 3.897c.278-.482.516-1.056.708-1.56.237.43.54.775.862 1.012-.596.123-1.115.374-1.57.548zm3.77-.143s-.143.171-1.069-.223c1.006-.074 1.172.154 1.069.223z" fill="currentColor" fill-rule="nonzero"/></svg>
                                    | 文件素材 {{j + 1}}
            el-dropdown-item
                a.link(@click="showFn")
                    <svg class="icon" width="11" height="15" viewBox="0 0 11 15" xmlns="http://www.w3.org/2000/svg"><path d="M10.596 2.807L8.193.404A1.375 1.375 0 007.222 0H1.375A1.38 1.38 0 000 1.378v11.914c0 .759.616 1.375 1.375 1.375h8.25c.76 0 1.375-.616 1.375-1.375V3.78c0-.364-.146-.716-.404-.974zm-1.083.862h-2.18v-2.18l2.18 2.18zm-8.138 9.623V1.378h4.583v2.978c0 .381.307.688.688.688h2.979v8.248h-8.25zM7.68 7.335a.341.341 0 00-.335.272c-.59 2.798-.585 2.732-.602 2.964-.006-.034-.011-.074-.02-.123-.023-.146.009.006-.676-2.85a.344.344 0 00-.335-.263h-.381a.35.35 0 00-.335.26c-.7 2.836-.688 2.756-.71 2.97-.003-.031-.006-.071-.015-.12-.02-.149-.404-2.1-.547-2.835a.344.344 0 00-.338-.278h-.481a.344.344 0 00-.335.424c.229.933.764 3.136.95 3.895.038.155.175.26.336.26h.722a.34.34 0 00.332-.26l.513-2.045c.043-.178.071-.344.086-.496l.083.496c.003.011.36 1.446.512 2.045.038.152.175.26.333.26h.707a.34.34 0 00.333-.26l.988-3.895a.342.342 0 00-.332-.427H7.68v.006z" fill="currentColor" fill-rule="nonzero"/></svg>
                    | 文字素材 1
</template>

<script>
export default {
    name: '',
    props: {
        pictureTypes: {
            type: Array,
            default() {
                return [];
            }
        },
        showTxtFn: {
            type: Function,
            default() {

            }
        },
        stage: {
            type: Number,
            default: 0
        }
    },
    methods: {
        showFn() {
            this.showTxtFn('text');
        }
    }
};
</script>

<style lang="sass" scoped>
    .icon
        margin-right: 10px
    .el-dropdown-menu
        .link
            font-size: 12px
            color: #696D74
            display: block
            text-decoration: none
        .icon
            vertical-align: middle
            display: inline-block
            margin-top: -2px
            margin-right: 5px
</style>