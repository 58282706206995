<template lang="pug">
    .items-wrap.orignal-cont
        .item
            .i-title 原始需求
            pre.cont(v-html="ticket.client_raw_requirement")
</template>

<script>
export default {
    name: '',
    props: {
        ticket: {
            type: Object,
            default() {
                return {};
            }
        }
    },
};
</script>

<style lang="sass" scoped>
    .cont
        padding: 20px
        overflow-y: scroll
        max-height: 500px
        border: 1px solid #CECECE
</style>