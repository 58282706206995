<template lang="pug">
    .part-status
        .vs-info
            .item(v-if="businessInfo.company")
                .title 甲方需求对接：
                .name(v-if="businessInfo.recruiter") {{businessInfo.recruiter.wechat_name || '未知'}}
            .item(v-if="businessInfo.ticket")
                .title 乙方项目经理：
                .name {{businessInfo.ticket.admin_user_name}}
        .arrange(v-if="businessInfo.specifications && businessInfo.specifications.length")
            .title 规格要求
            .date-items
                template(v-for="(item,index) in businessInfo.specifications")
                    .item
                        .name {{item.name}}
                        .line
                        .date {{item.value}}
        .arrange1(v-if="businessInfo.dates && businessInfo.dates.length")
            .date-items
                .item(v-for="(item,index) in businessInfo.dates", :key="item.id")
                    .name {{item.type.name}}
                    .line
                    .date {{item.date}}
        .vs-info.workhour
            .item(v-if="businessInfo.ticket")
                .title 本次消耗：
                .name
                    | {{businessInfo.ticket.hours_of_work}} 工时
                    el-dropdown(:showTimeout="0", placement="top")
                        span
                            <svg class="icon" width="12" height="12" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg"><path d="M5 0a5 5 0 10.002 10.002A5 5 0 005 0zm0 9.032A4.03 4.03 0 01.968 5 4.03 4.03 0 015 .968 4.03 4.03 0 019.032 5 4.03 4.03 0 015 9.032zm2.162-5.145c0 1.352-1.46 1.373-1.46 1.872v.128a.242.242 0 01-.242.242h-.92a.242.242 0 01-.242-.242v-.174c0-.721.546-1.01.96-1.24.353-.2.57-.334.57-.597 0-.348-.443-.579-.802-.579-.467 0-.683.222-.987.605a.242.242 0 01-.336.042l-.56-.425a.242.242 0 01-.054-.33c.476-.7 1.083-1.092 2.028-1.092.99 0 2.045.772 2.045 1.79zM5.847 7.258a.848.848 0 01-1.694 0 .848.848 0 011.694 0z" fill="currentColor" fill-rule="nonzero"/></svg>
                        el-dropdown-menu(slot="dropdown")
                            .date-items
                                el-dropdown-item.item
                                    .name
                                        b 工序
                                    .line
                                        b
                                    .name
                                        b 难度
                                    .line
                                        b
                                    .name
                                        b 工作量
                                template(v-for="(item,index) in businessInfo.skills")
                                    el-dropdown-item.item
                                        .name {{item.name}}
                                        .line
                                        .name {{item.skill_levels}}
                                        .line
                                        .date {{item.how_many_works}}
            .item(v-if="businessInfo.company && businessInfo.company.working_hours")
                    .title 剩余工时：
                    .name {{ businessInfo.company.working_hours}} 工时


        .aggree
            .flow
                el-button(v-if="isHaveGuide() === true" @click="getPreview" size="mini") 查看对接流程
            el-checkbox(@change="OnAagree" v-model="checked", v-if="stage === 1")
                .name 我已了解对接流程

        .original
            span.btn(v-if="stage === 2").disabled 执行中
            span.btn(v-if="stage === 4 && businessInfo.confirmed_at").finish
                | {{businessInfo.confirmed_at}} 确认完成

            span.btn.confirm(@click="onConfirm", v-if="stage === 1").disabled 确认需求
            span.btn.confirm(@click="onConfirm", v-if="stage === 3") 确认完成
</template>

<script>
import { Message } from 'element-ui';
import Api from '../../../common/api';



const STAGE = [0, 'pending', 'executing', 'confirming', 'finished'];
export default {
    name: '',
    props: {
        businessInfo: {
            type: Object,
            default() {
                return {};
            }
        },
        stage: {
            type: Number,
            default: 0
        },
    },
    data(){
        return {
            checked: false,
            is_agree:false,
        };
    },
    methods: {
        onConfirm() {
            const { id, stage } = this.$route.params;
            const index = STAGE.indexOf(stage);
            let newStage = STAGE[1];
            let nextIndex = index + 1;

            if (index === 1){
                if (this.is_agree === false){
                    return;
                }
            }

            if (nextIndex <= STAGE.length) {
                newStage = STAGE[nextIndex];
                this.stage = nextIndex;
            } else {
                this.stage = 1;
            }
            if (index === 1) {
                Api.confirmPending(id).then(() => {
                    this.$router.push({path: `/${id}/${newStage}` });
                    Message({
                        message: '需求确认成功！',
                        type: 'success'
                    });
                });
            } else if(index === 3) {
                Api.confirmed(id).then(() => {
                    this.$router.push({path: `/${id}/${newStage}` });
                    Message({
                        message: '确认成功！',
                        type: 'success'
                    });
                });
            }
        },
        OnAagree(e){
            this.is_agree = e;
            if(e){
                document.querySelector('.confirm').classList.remove('disabled');

            }else{
                document.querySelector('.confirm').classList.add('disabled');
            }
        },
        getPreview(){
            this.$parent.getPreview();
        },
        isHaveGuide(){
            return this.$parent.is_have_guide;
        }
    }
};
</script>

<style lang="sass" scoped>
.el-dropdown-menu
    .date-items
        width: 232px
    .item
        padding: 0
        color: #676767
        font-size: 12px
        margin-bottom: 0 !important
        &:hover
            background: none
        .name
            padding-left: 15px
        .date
            padding-right: 15px
.arrange,.el-dropdown-menu
    .item
        position: relative
        display: flex
        justify-content: space-between
        margin-bottom: 10px
    .line
        position: absolute
        top: 50%
        width: 100%
        height: 1px
        border-bottom: 1px dashed #CFCFD5
    .name
        color: #696D74
        padding-right: 10px
    .date
        padding-left: 10px
    .name,.date
        background-color: #fff
        position: relative
        z-index: 2
.part-status
    padding-top: 56px + 112px + 60px
    position: fixed
    top: 0
    margin-top: -4px
    width: 280px
    background: #FEFEFE
    box-shadow: 4px 1px 7px 0 rgba(218,218,218,0.50)
    border-radius: 0 19px 19px 0
    padding-left: 30px
    padding-right: 30px
    left: 50%
    transform: translateX(460px)
    .el-dropdown
        vertical-align: middle
        margin-top: -2px
        .icon
            margin-left: 4px
            position: relative
            &:hover
              color: #204ECF
    .vs-info,
    .arrange,
    .arrange1,
    .has-material,
    .original
        padding: 20px 0 10px
        font-size: 12px
        color: #696D74
        border-bottom: 1px solid #E4E4E4
        margin-bottom: 20px
        .title
            font-size: 12px
            color: #676767
            margin-bottom: 15px
    .vs-info
        .item
            display: flex
        .title
            margin-bottom: 4px
        .name
            font-weight: 500
    .arrange1
        .date-items
            margin-bottom: 15px
            .item
                position: relative
                padding-left: 8px
                border-left: 1px dashed #979797
                margin-bottom: 0
                padding-bottom: 26px
                font-size: 12px
                &:last-child
                    padding-bottom: 0
                    margin-bottom: 0
                    height: 6px
                &:before
                    left: -4px
                    top: 0
                    position: absolute
                    content: ' '
                    display: block
                    background: #676767
                    width: 7px
                    height: 7px
                    border-radius: 50%
            .name
                position: relative
                top: -5px
                display: inline-block
                text-align: center
            .line
                display: none
            .date
                position: relative
                top: -4px
                display: inline-block

        .name
            color: #696D74
            padding-right: 10px
        .name,.date
            position: relative
            z-index: 2
            background-color: #FEFEFE
    .has-material
        .tags
            display: flex
            flex-wrap: wrap
            justify-content: flex-start
        .tag
            color: #696D74
            text-decoration: none
            display: inline-block
            width: 70px
            height: 26px
            text-align: center
            line-height: 26px
            border: 1px solid #EDEDED
            border-radius: 4px
            margin-bottom: 10px
            cursor: pointer
            &:hover
                color: #2878FF
                background-color: #EBF0FA
                border-color: #EBF0FA
                box-shadow: 0 1px 4px 0 #F1F5FC
    .original
        border: none
        margin-bottom: 110px
        .btn
            display: inline-block
            cursor: pointer
            width: 203px
            height: 40px
            text-align: center
            line-height: 40px
            font-size: 12px
            color: #fff
            background: #00CC83
            box-shadow: 0 2px 4px 0 #D0E1FE
            border-radius: 4px
            &.disabled
                background: #DDDDDD
                cursor: not-allowed
                &:hover,&:active
                    background: #DDDDDD
            &.finish
                color: #0E0E0E
                text-align: left
                padding-left: 30px
                box-shadow: none
                background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAYAAADFw8lbAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAKqADAAQAAAABAAAAKgAAAADUGqULAAAD5klEQVRYCdWZTWwNURSAz5n+eE2IaFKkRJW0jVYRaiG1IbGSPEL8W1lYW5CIqoWWdGHztiJWJSHS6EtsSKxIJEqKqNRfVeiGEEE82s51zp13O/fNm/s68/57k/fmzr33nPPNuX9zzyDkkob62gGnoyDsVgCsJ1X1gIKvAAIn6J9+YgLQGgFREYeO0y9kXRZ/GFpGwk0dAwG7CKIxnDyOAcIgiMqrYaGDgw71rQCY7CFDRwnSCgfoaY1gk45+gKpuAv7oqfW9nR305c1q+DN6HhBPgBDzfLVkX5gg0RjUtJyDtv3/MqnJDPr0Yh3Y9gB18dZMSnKvwwdgWXtg45kvJl1m0CcX1hFgnLzYYBLOazniOE3IKGzqeu6n1x9UQtoPaRzN9xMqWBnCLwCr0w82HZS7W9iPi+ZJ71OzZ9Ha7B0GqbOXJw6PyWJ1txeS79k2MzCLllJBE697Cj9xNOvGLE1eXmm05Ha9s06OUl1Eqy9llpauqha1zmoepcW8fCDZQeQwySSd5XhUbouTwznvOFJlHv/kDla1gbdbx6Mo927Nu3k0losq3qqZjZIDJ18wctFYQNkkGwIv7sJ+VkBTUnV0YRNcWr4DEmIKjo/fgUe/Pwc3idZ6cq2IBpfIruXBRa0wsHofNEVqob1mMVxu2BlOETFaYIs14aTCtT5AkP2Nu6EC3SkQwcpwSoiRpOWbeTjBgK3Zk9c8kNPChpOf7gXUoJphPT+mc3RQZYZrNXmka2knXCfD2xesNLRyixnS60mGPDJ2G+I/3rgNg+UIVJ1xZhE4tWQL9C7bBodq18LdpsPAXWpKmSBvfB8xiZnLidEdOOZmsqatpm6mBY837lI/2LxDJq1aydPiDIQpc+XrMHDXqeQHWyhIZuTpx8faZgVgut7/+UGOL31yKFiW4b3YNCaz6u5UEAalc3fApAyaYPUlSE0cJRPQhKGZIFALX9FaamiQXqwMe2H1lvmFJM0UwKAFv3JQNxIkz7C8zOhjVsnlHZIVU5Ql+ZrX+57uGpWxoFee9bpnCwIJOAYdZ1c5yxOHWbJI7Nm9727B28Q3+eO8GhpZqPMXSbLNsRdnjrLJWJD/Q5WslJmSEUBtZ6KAFeLfkkGlG+bDXbcqdkGdqFpMVZTBNaZOoMzigvJdpJmegAJWJU/EwBE+LaWCcuiPo2oyYKW1KmaWbTODJwyZCspAMvRHUTUZsComIdmSNsm2T/gxHZTZZOiPomrF9Ky05R/JYyR/UAXLUbWijFkak2zLEBtlHGfB55wpzYnQuA5f9h8bdFjOl/3nGy8w33OUhQMYMjaQ4YMYv/MKjGcag37q9bL/4M2LGYeki4cAAAAASUVORK5CYII=') transparent left center no-repeat
                background-size: 21px
                width: 220px
                &:hover,&:active
                    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAYAAADFw8lbAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAKqADAAQAAAABAAAAKgAAAADUGqULAAAD5klEQVRYCdWZTWwNURSAz5n+eE2IaFKkRJW0jVYRaiG1IbGSPEL8W1lYW5CIqoWWdGHztiJWJSHS6EtsSKxIJEqKqNRfVeiGEEE82s51zp13O/fNm/s68/57k/fmzr33nPPNuX9zzyDkkob62gGnoyDsVgCsJ1X1gIKvAAIn6J9+YgLQGgFREYeO0y9kXRZ/GFpGwk0dAwG7CKIxnDyOAcIgiMqrYaGDgw71rQCY7CFDRwnSCgfoaY1gk45+gKpuAv7oqfW9nR305c1q+DN6HhBPgBDzfLVkX5gg0RjUtJyDtv3/MqnJDPr0Yh3Y9gB18dZMSnKvwwdgWXtg45kvJl1m0CcX1hFgnLzYYBLOazniOE3IKGzqeu6n1x9UQtoPaRzN9xMqWBnCLwCr0w82HZS7W9iPi+ZJ71OzZ9Ha7B0GqbOXJw6PyWJ1txeS79k2MzCLllJBE697Cj9xNOvGLE1eXmm05Ha9s06OUl1Eqy9llpauqha1zmoepcW8fCDZQeQwySSd5XhUbouTwznvOFJlHv/kDla1gbdbx6Mo927Nu3k0losq3qqZjZIDJ18wctFYQNkkGwIv7sJ+VkBTUnV0YRNcWr4DEmIKjo/fgUe/Pwc3idZ6cq2IBpfIruXBRa0wsHofNEVqob1mMVxu2BlOETFaYIs14aTCtT5AkP2Nu6EC3SkQwcpwSoiRpOWbeTjBgK3Zk9c8kNPChpOf7gXUoJphPT+mc3RQZYZrNXmka2knXCfD2xesNLRyixnS60mGPDJ2G+I/3rgNg+UIVJ1xZhE4tWQL9C7bBodq18LdpsPAXWpKmSBvfB8xiZnLidEdOOZmsqatpm6mBY837lI/2LxDJq1aydPiDIQpc+XrMHDXqeQHWyhIZuTpx8faZgVgut7/+UGOL31yKFiW4b3YNCaz6u5UEAalc3fApAyaYPUlSE0cJRPQhKGZIFALX9FaamiQXqwMe2H1lvmFJM0UwKAFv3JQNxIkz7C8zOhjVsnlHZIVU5Ql+ZrX+57uGpWxoFee9bpnCwIJOAYdZ1c5yxOHWbJI7Nm9727B28Q3+eO8GhpZqPMXSbLNsRdnjrLJWJD/Q5WslJmSEUBtZ6KAFeLfkkGlG+bDXbcqdkGdqFpMVZTBNaZOoMzigvJdpJmegAJWJU/EwBE+LaWCcuiPo2oyYKW1KmaWbTODJwyZCspAMvRHUTUZsComIdmSNsm2T/gxHZTZZOiPomrF9Ky05R/JYyR/UAXLUbWijFkak2zLEBtlHGfB55wpzYnQuA5f9h8bdFjOl/3nGy8w33OUhQMYMjaQ4YMYv/MKjGcag37q9bL/4M2LGYeki4cAAAAASUVORK5CYII=') transparent left center no-repeat
                    background-size: 21px
            &:hover,&:active
                background: #00A369

</style>

<style lang="sass">

    .flow
        margin-top: 35px
        margin-bottom: 10px

        .el-button--mini
            &:hover
                color: #0E0E0E
                border-color: #0E0E0E
                background-color: #ffffff

    .el-checkbox
        margin-bottom: 10px
        .name
            font-size: 12px
        &:hover
            color: #0E0E0E
    .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner
        background-color: #0E0E0E
        border-color: #0E0E0E

    .el-checkbox__input.is-checked + .el-checkbox__label
        color: #0E0E0E

    .el-checkbox.is-bordered.is-checked
        border-color: #0E0E0E

    .el-checkbox__input.is-focus .el-checkbox__inner
        border-color: #0E0E0E
</style>