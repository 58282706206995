<template lang="pug">
    .items-wrap(v-if="pics.length")
        template(v-for="item in pics")
            template(v-if="item.id === id")
                .item(:id="'picture_types_' + item.id")
                    .i-title(v-if="is_show_like") 选择您的喜欢的设计风格
                    .i-title(v-if="!is_show_like") {{item.name}}
                    .cont
                        template(v-if="item.pictures.length <= 3")
                            .s-imgs
                                .imgs(v-for="(pics, i) in item.pictures", :key="pics.id")
                                    .imgs-solid
                                        el-image.img(
                                            @click="showImgHandle(pics.id, i)"
                                            :src="pics.attachment.pub_url + '?x-oss-process=style/list'",
                                            :preview-src-list="[pics.attachment.pub_url]"
                                        )
                                        span.new(v-if="showDetail && item.pictures.length === (i + 1)") New
                                        span.time(v-if="showDetail")
                                            <svg class="icon" width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6 0C2.685 0 0 2.685 0 6s2.685 6 6 6 6-2.685 6-6-2.685-6-6-6zm0 10.839A4.837 4.837 0 011.161 6 4.837 4.837 0 016 1.161 4.837 4.837 0 0110.839 6 4.837 4.837 0 016 10.839zm1.495-2.526L5.441 6.82a.292.292 0 01-.118-.235V2.613c0-.16.13-.29.29-.29h.774c.16 0 .29.13.29.29V6.04l1.617 1.176a.29.29 0 01.062.406l-.454.627a.292.292 0 01-.407.063z" fill="#4A4A4A" fill-rule="nonzero"/></svg>
                                            | 交付时间：{{pics.updated_at}}
                                        span.op(v-if="showDetail && pics.comment_content", @click="openCmt(pics.comment_content)")
                                            <svg class="icon" width="12" height="10" viewBox="0 0 12 10" xmlns="http://www.w3.org/2000/svg"><path d="M11.082 7.38c.573-.565.917-1.274.917-2.047 0-1.666-1.594-3.043-3.67-3.29C7.671.844 6.13 0 4.332 0 1.939 0 0 1.492 0 3.333c0 .771.344 1.48.917 2.046A5.455 5.455 0 01.13 6.523a.48.48 0 00-.091.52.48.48 0 00.441.292c1.115 0 2.015-.42 2.608-.808.192.044.39.077.592.102.654 1.196 2.19 2.038 3.985 2.038.433 0 .85-.05 1.246-.142.593.385 1.491.808 2.608.808a.482.482 0 00.35-.812 5.31 5.31 0 01-.788-1.142zM2.9 5.455l-.356.231c-.294.19-.594.34-.898.446.056-.098.112-.202.166-.308l.323-.648-.516-.51C1.337 4.387 1 3.93 1 3.333 1 2.07 2.527 1 4.333 1c1.806 0 3.333 1.069 3.333 2.333 0 1.265-1.527 2.334-3.333 2.334-.344 0-.687-.04-1.02-.117L2.9 5.456zm7.48 1.21l-.514.509.323.648c.054.106.11.21.166.308a4.238 4.238 0 01-.898-.446l-.356-.23-.414.095a4.539 4.539 0 01-1.021.117c-1.125 0-2.129-.42-2.735-1.036 2.11-.225 3.735-1.612 3.735-3.298 0-.07-.009-.14-.015-.208C9.993 3.427 11 4.308 11 5.333c0 .598-.338 1.054-.619 1.334z" fill="#4A4A4A" fill-rule="nonzero"/></svg>
                                            | 查看修改意见
                                        span.checks(v-if="is_show_like")
                                            <svg @click="like(pics.id, i)" :class="{'right': true, 'current': (collections[i] && collections[i].isLike) === true }" width="23" height="23" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg"><path d="M11.5 0C5.149 0 0 5.149 0 11.5S5.149 23 11.5 23 23 17.851 23 11.5 17.851 0 11.5 0zm0 2.226a9.27 9.27 0 019.274 9.274 9.27 9.27 0 01-9.274 9.274A9.27 9.27 0 012.226 11.5 9.27 9.27 0 0111.5 2.226m6.501 6.04l-1.045-1.053a.556.556 0 00-.787-.003l-6.554 6.502-2.773-2.795a.556.556 0 00-.787-.004l-1.053 1.045a.556.556 0 00-.003.787l4.21 4.244c.216.218.568.22.786.003l8.003-7.939a.557.557 0 00.003-.787z" fill="currentColor" fill-rule="nonzero"/></svg>
                                            <svg @click="dislike(pics.id, i)" :class="{'error': true, 'current': (collections[i] && collections[i].isLike) === false }" width="23" height="23" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg"><path d="M11.5 0C5.147 0 0 5.147 0 11.5S5.147 23 11.5 23 23 17.853 23 11.5 17.853 0 11.5 0zm0 20.774A9.272 9.272 0 012.226 11.5 9.272 9.272 0 0111.5 2.226a9.272 9.272 0 019.274 9.274 9.272 9.272 0 01-9.274 9.274zm4.72-12.158L13.337 11.5l2.885 2.884a.557.557 0 010 .789l-1.048 1.048a.557.557 0 01-.789 0L11.5 13.336l-2.884 2.885a.557.557 0 01-.789 0L6.78 15.173a.557.557 0 010-.789L9.664 11.5 6.779 8.616a.557.557 0 010-.789L7.827 6.78a.557.557 0 01.789 0L11.5 9.664l2.884-2.885a.557.557 0 01.789 0l1.048 1.048a.557.557 0 010 .789z" fill="currentColor" fill-rule="nonzero"/></svg>
                        template(v-else)
                            swiper(ref="mySwiper", :options="swiperOptions", @slideChange="slideChange")
                                swiper-slide.imgs(v-for="(pics, i) in item.pictures", :key="pics.id")
                                    .imgs-solid
                                        el-image.img(
                                            @click="showImgHandle(pics.id, i)"
                                            :src="pics.attachment.pub_url + '?x-oss-process=style/list'",
                                            :preview-src-list="[pics.attachment.pub_url]"
                                        )
                                        span.new(v-if="showDetail && item.pictures.length === (i + 1)") New
                                        span.time(v-if="showDetail")
                                            <svg class="icon" width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6 0C2.685 0 0 2.685 0 6s2.685 6 6 6 6-2.685 6-6-2.685-6-6-6zm0 10.839A4.837 4.837 0 011.161 6 4.837 4.837 0 016 1.161 4.837 4.837 0 0110.839 6 4.837 4.837 0 016 10.839zm1.495-2.526L5.441 6.82a.292.292 0 01-.118-.235V2.613c0-.16.13-.29.29-.29h.774c.16 0 .29.13.29.29V6.04l1.617 1.176a.29.29 0 01.062.406l-.454.627a.292.292 0 01-.407.063z" fill="#4A4A4A" fill-rule="nonzero"/></svg>
                                            | 交付时间：{{pics.updated_at}}
                                        span.op(v-if="showDetail && pics.comment_content", @click="openCmt(pics.comment_content)")
                                            <svg class="icon" width="12" height="10" viewBox="0 0 12 10" xmlns="http://www.w3.org/2000/svg"><path d="M11.082 7.38c.573-.565.917-1.274.917-2.047 0-1.666-1.594-3.043-3.67-3.29C7.671.844 6.13 0 4.332 0 1.939 0 0 1.492 0 3.333c0 .771.344 1.48.917 2.046A5.455 5.455 0 01.13 6.523a.48.48 0 00-.091.52.48.48 0 00.441.292c1.115 0 2.015-.42 2.608-.808.192.044.39.077.592.102.654 1.196 2.19 2.038 3.985 2.038.433 0 .85-.05 1.246-.142.593.385 1.491.808 2.608.808a.482.482 0 00.35-.812 5.31 5.31 0 01-.788-1.142zM2.9 5.455l-.356.231c-.294.19-.594.34-.898.446.056-.098.112-.202.166-.308l.323-.648-.516-.51C1.337 4.387 1 3.93 1 3.333 1 2.07 2.527 1 4.333 1c1.806 0 3.333 1.069 3.333 2.333 0 1.265-1.527 2.334-3.333 2.334-.344 0-.687-.04-1.02-.117L2.9 5.456zm7.48 1.21l-.514.509.323.648c.054.106.11.21.166.308a4.238 4.238 0 01-.898-.446l-.356-.23-.414.095a4.539 4.539 0 01-1.021.117c-1.125 0-2.129-.42-2.735-1.036 2.11-.225 3.735-1.612 3.735-3.298 0-.07-.009-.14-.015-.208C9.993 3.427 11 4.308 11 5.333c0 .598-.338 1.054-.619 1.334z" fill="#4A4A4A" fill-rule="nonzero"/></svg>
                                            | 查看修改意见
                                        span.checks(v-if="is_show_like")
                                            <svg @click="like(pics.id, i)" :class="{'right': true, 'current': (collections[i] && collections[i].isLike) === true }" width="23" height="23" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg"><path d="M11.5 0C5.149 0 0 5.149 0 11.5S5.149 23 11.5 23 23 17.851 23 11.5 17.851 0 11.5 0zm0 2.226a9.27 9.27 0 019.274 9.274 9.27 9.27 0 01-9.274 9.274A9.27 9.27 0 012.226 11.5 9.27 9.27 0 0111.5 2.226m6.501 6.04l-1.045-1.053a.556.556 0 00-.787-.003l-6.554 6.502-2.773-2.795a.556.556 0 00-.787-.004l-1.053 1.045a.556.556 0 00-.003.787l4.21 4.244c.216.218.568.22.786.003l8.003-7.939a.557.557 0 00.003-.787z" fill="currentColor" fill-rule="nonzero"/></svg>
                                            <svg @click="dislike(pics.id, i)" :class="{'error': true, 'current': (collections[i] && collections[i].isLike) === false }" width="23" height="23" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg"><path d="M11.5 0C5.147 0 0 5.147 0 11.5S5.147 23 11.5 23 23 17.853 23 11.5 17.853 0 11.5 0zm0 20.774A9.272 9.272 0 012.226 11.5 9.272 9.272 0 0111.5 2.226a9.272 9.272 0 019.274 9.274 9.272 9.272 0 01-9.274 9.274zm4.72-12.158L13.337 11.5l2.885 2.884a.557.557 0 010 .789l-1.048 1.048a.557.557 0 01-.789 0L11.5 13.336l-2.884 2.885a.557.557 0 01-.789 0L6.78 15.173a.557.557 0 010-.789L9.664 11.5 6.779 8.616a.557.557 0 010-.789L7.827 6.78a.557.557 0 01.789 0L11.5 9.664l2.884-2.885a.557.557 0 01.789 0l1.048 1.048a.557.557 0 010 .789z" fill="currentColor" fill-rule="nonzero"/></svg>
                                .swiper-pagination(slot="pagination")
                            .swiper-button-prev(slot="button-prev")
                            .swiper-button-next(slot="button-next")
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import { Message } from 'element-ui';
import 'swiper/css/swiper.css';
import Api from '../../../common/api';

export default {
    name: 'Pictures',
    components: {
        Swiper,
        SwiperSlide
    },
    directives: {
        swiper: directive
    },



    data() {
        let is_loop = true;
        let pagination_class = '.swiper-pagination';

        if(this.businessInfo.dates && this.businessInfo.dates.length == 0){
            is_loop = false;
            pagination_class = '';
        }

        return {
            collections: [],
            swiperOptions: {
                initialSlide: 0,
                slidesPerView: 3,
                spaceBetween: 30,
                centeredSlides: true,
                loop: is_loop,
                allowTouchMove : false,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: pagination_class,
                    clickable: true,
                },
            }
        };
    },
    mounted() {
        if (this.pics.length) {
            this.pics.forEach(item => {
                if (item.id === this.id) {
                    item.pictures.forEach(pic => {
                        this.collections.push(Object.assign({}, pic, { isLike: pic.collection ?  pic.collection.is_recruiter_like : -1 }));
                    });
                }
            });
        }
    },
    computed: {
        swiper() {
            if (this.$refs.mySwiper) {
                return this.$refs.mySwiper[0].$swiper;
            } else {
                return {
                    slideNext() {

                    },
                    slideTo() {
                    }
                };
            }
        }
    },
    props: {
        pics: {
            type: Array,
            default() {
                return [];
            }
        },
        showDetail: {
            type: Boolean,
            default: false
        },
        id: {
            type: Number,
            default: 1
        },

        businessInfo: {
            type: Object,
            default() {
                return {};
            }
        },
        is_show_like:Boolean
    },
    methods: {
        slideChange() {
            this.$forceUpdate();
        },
        openCmt(cont) {
            this.$alert(cont, '查看修改意见', {
                dangerouslyUseHTMLString: true
            });
        },
        markCollection(id, sign) {
            Api.confirmCollection(id,{
                is_recruiter_like: sign,
            }).then(() => {
            }).catch((e) => {
                Message({
                    message: '请检查您的网络' + e,
                    type: 'error'
                });
            });
        },
        like(id, index) {
            this.markCollection(id, true);
            console.log('this.collections[index].isLike', this.collections[index].isLike);
            if (this.collections[index].isLike === true) {
                this.collections[index].isLike = -1;
            } else {
                this.collections[index].isLike = true;

                if(index == this.collections.length -1){
                    this.swiper.slideTo(0);
                    Message({
                        message: '已了解您喜欢的设计风格，稍后项目经理会跟您确认具体的需求',
                        offset:800,
                        type: 'success',
                        duration:0,
                        showClose:true,
                    });
                }else{
                    this.swiper.slideNext();
                    Message({
                        message: '已标记喜欢',
                        offset:800,
                        type: 'success',
                        duration:1000,
                    });
                }
            }
        },
        dislike(id, index) {

            this.markCollection(id, false);
            if (this.collections[index].isLike === false) {
                this.collections[index].isLike = -1;
            }
            if (this.collections[index].isLike === true || this.collections[index].isLike === -1) {
                this.collections[index].isLike = false;
                if(index == this.collections.length -1){
                    this.swiper.slideTo(0);
                    Message({
                        message: '已了解您喜欢的设计风格，稍后项目经理会跟您确认具体的需求',
                        offset:800,
                        type: 'success',
                        duration:0,
                        showClose:true,
                    });
                }else{
                    this.swiper.slideNext();
                    Message({
                        message: '已标记不喜欢',
                        offset:800,
                        type: 'error',
                        duration:1000,
                    });
                }
            }
        },
        showImgHandle(id, index) {
            if (!this.is_show_like) {
                return false;
            }
            let self = this;
            setTimeout(() => {
                let el = document.querySelector('.el-image-viewer__actions__inner');
                let svgLike = document.createElement('span');
                let svgDislike = document.createElement('span');
                svgLike.className = `el-collection-like ${this.collections[index].isLike === true ? 'current' : ''}`;
                svgDislike.className = `el-collection-dislike ${this.collections[index].isLike === false ? 'current' : ''}`;
                svgLike.innerHTML = `<svg width="20" height="20" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg"><path d="M11.5 0C5.149 0 0 5.149 0 11.5S5.149 23 11.5 23 23 17.851 23 11.5 17.851 0 11.5 0zm0 2.226a9.27 9.27 0 019.274 9.274 9.27 9.27 0 01-9.274 9.274A9.27 9.27 0 012.226 11.5 9.27 9.27 0 0111.5 2.226m6.501 6.04l-1.045-1.053a.556.556 0 00-.787-.003l-6.554 6.502-2.773-2.795a.556.556 0 00-.787-.004l-1.053 1.045a.556.556 0 00-.003.787l4.21 4.244c.216.218.568.22.786.003l8.003-7.939a.557.557 0 00.003-.787z" fill="currentColor" fill-rule="nonzero"/></svg>`;
                svgDislike.innerHTML = `<svg width="20" height="20" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg"><path d="M11.5 0C5.147 0 0 5.147 0 11.5S5.147 23 11.5 23 23 17.853 23 11.5 17.853 0 11.5 0zm0 20.774A9.272 9.272 0 012.226 11.5 9.272 9.272 0 0111.5 2.226a9.272 9.272 0 019.274 9.274 9.272 9.272 0 01-9.274 9.274zm4.72-12.158L13.337 11.5l2.885 2.884a.557.557 0 010 .789l-1.048 1.048a.557.557 0 01-.789 0L11.5 13.336l-2.884 2.885a.557.557 0 01-.789 0L6.78 15.173a.557.557 0 010-.789L9.664 11.5 6.779 8.616a.557.557 0 010-.789L7.827 6.78a.557.557 0 01.789 0L11.5 9.664l2.884-2.885a.557.557 0 01.789 0l1.048 1.048a.557.557 0 010 .789z" fill="currentColor" fill-rule="nonzero"/></svg>`;
                svgLike.addEventListener('click', function(){
                    if (this.classList.contains('current')) {
                        this.classList.remove('current');
                    } else {
                        document.querySelector('.el-collection-dislike').classList.remove('current');
                        this.classList.add('current');
                    }
                    self.like(id, index);
                });
                svgDislike.addEventListener('click', function(){
                    if (this.classList.contains('current')) {
                        this.classList.remove('current');
                    } else {
                        document.querySelector('.el-collection-like').classList.remove('current');
                        this.classList.add('current');
                    }
                    self.dislike(id, index);
                });
                el.appendChild(svgLike);
                el.appendChild(svgDislike);
            }, 0);
        }
    }
};
</script>

<style lang="sass" scoped>
    .cont
        

        /deep/ .el-image__inner
            width: 236px
            height: 236px
            object-fit: contain
            box-shadow: 0 1px 4px 0 #E9EBF0

        position: relative
        .swiper-button-prev,
        .swiper-button-next
            background: #FFFFFF
            box-shadow: 0 1px 4px 0 #E9EBF0
            width: 32px
            height: 32px
            border-radius: 50%
            color: #9599A0
            &:hover
                color: #204ECF
            &:after
                font-size: 14px
        .swiper-button-prev
            left: -40px
        .swiper-button-next
            right: -40px
        .s-imgs
            display: flex
            justify-content: center
            .imgs-solid
                margin: 0 15px
        .imgs-solid
            position: relative
            .new
                background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAMAAABU4iNhAAAAe1BMVEUAAAAjU9YsVdMzYOMgTtAzXNxhcP8gTtAnVdgmU9kgTtAgTtAhTtAiUdIgT88gTtAgTtAhTtAhTtAgTs8gTs8hT9AgT9AhT9AgT9AhT9EhUdEhT9IiUtIlT9EhT9AgT9AgT9AhT88hT9EhUNAjTtEjUdEiT9InTtggTs9puSgcAAAAKHRSTlMAFREG+goC6hMN9fGvJebd1s7GuZmSem5iUD01LR7iv6ehioNYSEQaseUPMAAAALBJREFUOMuNykeSwjAAAMGxZMk2YJa4iY1E//+FFGUojpo+Nw8xdSGEtq3u2jbcdF1KMcacAfrZfFoPBfURqmYoqz+ArY25LsfJiRsRz7g5+cTN6SNSijvcbHa42XzxVIhuznvc/Olx8+UbMcfo5uKCm4sKN5cVbi4Dbq4Cbq463PxNuLlOuLmOuDmLiDlGN/8ibv5n3Nxk3NyAm1vk3CPnATlfEcYo5xuKjgzvSDZeAe7Pd67LY7yKAAAAAElFTkSuQmCC') no-repeat left top
                position: absolute
                left: 0
                top: 0
                width: 46px
                height: 40px
                font-size: 10px
                padding-left: 2px
                padding-top: 2px
                color: #fff
            .checks
                padding-top: 16px
                display: block
                text-align: center
                .right,.error
                    cursor: pointer
                    margin: 0 6px
                    color: #D6D6D8
                    &:hover
                        color: #333
                .right
                    &.current
                        color: #00A369
                .error
                    &.current
                        color: #F95F5F
        .time
            font-size: 11px
            color: #4A4A4A
            margin-top: 16px
            margin-left: 16px
            display: block
        .op
            text-align: left
            font-size: 11px
            color: #4A4A4A
            margin-left: 16px
            display: block
        // display: none

        .is-activ  .img
                box-shadow: 0 0px 5px #7C7C7C
        .img
            border-radius: 4px
            background-color: #fff
            box-shadow: 0 0px 4px #E9EBF0
            border-radius: 4px
    .swiper-container
        padding: 10px
        height: 300px
        .swiper-slide
            width: 236px
            height: 236px
            transition: 300ms
            transform: scale(0.8)
            .el-image
                box-shadow: 0 1px 4px 0 #E9EBF0
            /deep/ .el-image__inner
                width: 236px
                height: 236px
                object-fit: contain
        .swiper-slide-active,.swiper-slide-duplicate-active
            transform: scale(1)
</style>