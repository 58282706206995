<template lang="pug">
    .page-status
        .status(:class="{'current': stage === 1, finish: stage > 1 }")
            i.icon
            | 需求确认中
        .line
        .status(:class="{'current': stage === 2, finish: stage > 2 }")
            i.icon
            | 执行阶段
        .line
        .status(:class="{'current': stage === 3, finish: stage > 3 }")
            i.icon
            | 交付阶段
        .line
        .status(:class="{'current': stage == 4, finish: stage == 4 }")
            i.icon
            | 完成阶段
</template>

<script>
export default {
    props: {
        stage: {
            type: Number,
            default: 1,
        }
    }
};
</script>

<style lang="sass" scoped>
    .page-status
        z-index: 1000
        background-color: #F7F7F7
        left: 0
        top: 56px
        width: 100%
        position: fixed
        height: 112px
        display: flex
        align-items: center
        justify-content: center
        border-bottom: 1px solid #EAEAEA
        .status
            font-size: 14px
            color: #676767
            .icon
                display: inline-block
                width: 22px
                height: 22px
                border-radius: 50%
                vertical-align: middle
                margin-top: -3px
                margin-right: 8px
                background-color: #fff
                border: 6px solid #DBE0EE
            &.finish
                .icon
                    border: none
                    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAMAAADyHTlpAAAAVFBMVEUAAAAgTtAhT9AhU9MiT9IgTtAhT88gTtAhT9AgT88hT88gTtEiT9AiVdUkVdsrVdUhTtAhT88gTs/+/v9sit/f5vhWedvX3/axwe6Lo+ZlhN1khN3xYOVzAAAAEnRSTlMAwfMuLfjg3tmloY+IHhUGfHsK7zKSAAABBUlEQVQ4y5WVWbaDIAxAA6iAWqtBbV+7/32+00EGG6b7x+F+EDLBiWbRTHGumF4aSNAMEj3kELMvPf7QXwjxOrZI0I7XszkxjMCm0BQdRulEYHJMwD136jBJN9mIGGZgR2wjZhm//9nm1fbzvz0W0L+ziUW8cjyUqQMAyDJVAgiM89xuuz0ImOPmaoy52dMMOmmazR41sKRpni5joNDj776H5ooWBdw37aU1HTxQ7/bamU4NHrAfAmGi8sOyCmUiA02ETZmozylYYyYu0CDprlRpybNLm5Iqwse2PYgirCjt8oapaMPy5q4YGfWDKD/e6odmfhSXD/j6tVG/jBxiPlbcLCDkHzy6YHR+HoaAAAAAAElFTkSuQmCC') left center
                    background-size: 22px
            &.current
                color: #204ECF
                .icon
                    border-color: #204ECF
        .line
            width: 62px
            opacity: 0.76
            margin: 0 30px
            border: 1px dashed #8A8A8A
</style>