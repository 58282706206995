import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import Index from '../pages/index/index.vue';
import Tasks from '../pages/tasks/index.vue';
import Time from '../pages/remain-time/index.vue';

const routes = [
    { path: '/tasks/:id', component: Tasks },
    { path: '/remain-time/:id', component: Time },
    { path: '/:id/:stage', component: Index },
];

export const router = new Router({
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        selector: to.hash,
                        behavior: 'smooth',
                        offset: { y: 60 }
                    });
                }, 0);
            });
        }
        return { x: 0, y: 0 };
    }
});