<template lang="pug">
    .page-pending
        <Header :company="businessInfo.company" />
        <Steps :stage="stage" />
        .page-content
            .inner.mod-inner
                template(v-if="!is_show_like")
                    <Directory :businessInfo="businessInfo" :stage="stage" />
                .part-main(v-if="businessInfo.id")
                    //任务描述
                    <Title :businessInfo="businessInfo" :stage="stage" />
                    //查看原始需求
                    template(v-for="item in businessInfo.picture_types")
                        template(v-if="item.id == 9")
                                template(v-for="(pics, i) in item.pictures")
                                    .raw-btn(v-if="pics.attachment.pub_url")  查看原始需求
                                    .raw_image
                                        el-image.img(
                                            :src="pics.attachment.pub_url + '?x-oss-process=style/list'",
                                            :preview-src-list="[pics.attachment.pub_url]"
                                        )
                    //其他特别要求
                    <OtherReqText :businessInfo="businessInfo" />


                    <Pictures :pics="businessInfo.picture_types" :id="1" :businessInfo="businessInfo" :is_show_like="is_show_like"/>
                    <AssetPictureDesc :businessInfo="businessInfo" :desc_text="businessInfo.ticket.client_like_picture_desc"/>


                    //偏好风格文件
                    <Files v-if="businessInfo.picture_types" :pics="businessInfo.picture_types" :id="2" />
                    //偏好风格文件说明
                    <FilesAssetDesc :businessInfo="businessInfo" :desc_text="businessInfo.ticket.client_like_file_desc"/>

                    //已有图片素材
                    <Pictures :pics="businessInfo.picture_types" :id="3" />
                        //已有图片素材说明
                    <AssetPictureDesc :businessInfo="businessInfo" :desc_text="businessInfo.ticket.asset_picture_desc" />



                    //已有文件素材
                    <Files :pics="businessInfo.picture_types" :id="4" />
                    //已有文件素材说明
                    <FilesAssetDesc :businessInfo="businessInfo" :desc_text="businessInfo.ticket.asset_file_desc"/>
            


                    //资料素材文件
                    <Files :pics="businessInfo.picture_types" :id="10" />
                    //资料素材文件说明
                    <FilesAssetDesc  :businessInfo="businessInfo" :desc_text="businessInfo.ticket.info_file_desc" />



                    //设计基础图片
                    <Pictures :pics="businessInfo.picture_types" :id="11" />
                    //已有图片素材说明
                    <AssetPictureDesc :businessInfo="businessInfo" :desc_text="businessInfo.ticket.design_base_pic_desc" />


                    //设计基础文件
                    <Files :pics="businessInfo.picture_types" :id="12" />
                    //偏好风格文件说明
                    <FilesAssetDesc :businessInfo="businessInfo" :desc_text="businessInfo.ticket.desgin_base_file_desc"/>
                    

                    //文字基础文件
                    <Files :pics="businessInfo.picture_types" :id="13" />
                    //文字基础文件说明
                    <FilesAssetDesc :businessInfo="businessInfo" :desc_text="businessInfo.ticket.ext_base_file_desc"/>





            template(v-if="!is_show_like")
                <Status :businessInfo="businessInfo" :stage="stage" />

</template>

<script>
import { Message } from 'element-ui';
import Api from '../../common/api';

import Header from './components/header';
import Steps from './components/steps'; // 步骤
import Directory from './components/directory'; // 左侧栏
import Status from './components/status'; // 右侧栏
import Dates from './components/dates'; // 日期
import Title from './components/title'; // 标题
import Files from './components/files'; // 文件
import Pictures from './components/pictures'; // 图片
import FilesAssetDesc from './components/files_asset_desc'; //偏好文件说明
import Original from './components/original'; // 原始需求
import OtherReq from './components/otherReq'; // 其他需求
import OtherReqText from './components/otherReqText'; // 其他文字要求
import Texts from './components/texts'; // 文本素材
import Dropdown from './components/dropdown'; // 文本素材
import FileLikeDesc from './components/client_like_file_desc'; // 偏好风格图片文字说明
import AssetPictureDesc from './components/asset_picture_desc'; // 偏好风格图片文字说明
import AssetTxt from './components/asset_txt'; // 偏好风格图片文字说明

import TextAsset from './components/text_asset';

import introJs from 'intro.js';
import 'intro.js/introjs.css';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';


const STAGE = [0, 'pending', 'executing', 'confirming', 'finished'];

export default {
    name: 'Index',
    components: {
        Header, Steps, Directory, Status,
        Title, Files, Original, Pictures, Texts, OtherReq, Dates, Dropdown, FileLikeDesc,AssetPictureDesc,AssetTxt,ElImageViewer,OtherReqText,FilesAssetDesc,TextAsset
    },
    data() {
        return {
            businessInfo: {},
            showSingle: '',
            stage: 4,
            id: '',
            flow_image_url : '',
            showViewer:false,
            is_have_guide:false,
            is_show_like:false,
        };
    },
    watch: {
        $route() {
            this.init();
        }
    },
    created() {
        this.init();
    },
    mounted() {
    },
    methods:{
        init() {
            const { id, stage } = this.$route.params;
            if (id) {
                this.id = id;
                const loading = this.$loading({
                    text: '获取信息中...',
                });
                Api.get(`/api/tasks/${id}/${stage}`).then(data => {
                    this.businessInfo = data;

                    if(data.dates.length){
                        this.$cookies.set('intro',1,'365d');
                        if(this.$cookies.get('intro') == 1){
                            console.log('no guide');
                        }else{
                            this.guide();
                        }
                    }

                    let have_guid = [1,26,6,28,4,3,13,8];
                    let task_categories_id = this.businessInfo.task_categories_id;

                    if(!have_guid.includes(task_categories_id)){
                        this.is_have_guide = false;
                    }else{
                        this.is_have_guide = true;
                    }

                    if (task_categories_id == 1 || task_categories_id == 26){
                        this.flow_image_url =  'https://rwdd-pro.oss-cn-beijing.aliyuncs.com/image/%E6%9C%8B%E5%8F%8B%E5%9C%88%E9%95%BF%E5%9B%BE-%E7%94%B5%E5%95%86%E8%AF%A6%E6%83%85%E9%A1%B5.png';
                    }

                    else if (task_categories_id == 6 || task_categories_id == 28){
                        this.flow_image_url =  'https://rwdd-pro.oss-cn-beijing.aliyuncs.com/image/%E5%A4%B4%E5%9B%BE-Banner-%E5%B0%81%E9%9D%A2.png';
                    }
                    else if (task_categories_id == 4 ){
                        this.flow_image_url =  'https://rwdd-pro.oss-cn-beijing.aliyuncs.com/image/%E6%8F%92%E7%94%BB-%E6%8F%92%E5%9B%BE.png';
                    }
                    else if (task_categories_id == 3 || task_categories_id == 25 || task_categories_id == 27 || task_categories_id == 21|| task_categories_id == 22|| task_categories_id == 20){
                        this.flow_image_url =  'https://rwdd-pro.oss-cn-beijing.aliyuncs.com/image/%E6%B5%B7%E6%8A%A5-%E4%BC%A0%E5%8D%95-%E6%98%93%E6%8B%89%E5%AE%9D-%E9%82%80%E8%AF%B7%E5%87%BD-%E8%83%8C%E6%99%AF%E6%9D%BF-%E6%98%8E%E4%BF%A1%E7%89%87.png';
                    }
                    else if (task_categories_id == 13 ){
                        this.flow_image_url =  'https://rwdd-pro.oss-cn-beijing.aliyuncs.com/image/%E7%94%BB%E5%86%8C.png';
                    }
                    else if (task_categories_id == 8){
                        this.flow_image_url =  'https://rwdd-pro.oss-cn-beijing.aliyuncs.com/image/%E7%A4%BE%E4%BA%A4%E5%AA%92%E4%BD%93%E6%96%87%E7%AB%A0.png';
                    }
                    else{
                        this.flow_image_url =  'https://rwdd-pro.oss-cn-beijing.aliyuncs.com/image/%E6%9C%8B%E5%8F%8B%E5%9C%88%E9%95%BF%E5%9B%BE-%E7%94%B5%E5%95%86%E8%AF%A6%E6%83%85%E9%A1%B5.png';
                    }

                    setTimeout(() => {
                        loading.close();
                    }, 500);

                    if(STAGE.indexOf(stage) !== this.businessInfo.stage){
                        this.$router.push('/'+id+'/'+STAGE[this.businessInfo.stage]);
                    }else{
                        this.stage = STAGE.indexOf(stage);
                    }


                    if (data.freelancers.length) {
                        this.is_show_like = false;
                    }else{
                        this.is_show_like = true;
                    }
                }).catch(() => {
                    loading.close();
                });
            } else {
                Message({
                    message: '地址错误，请联系管理员！',
                    type: 'error'
                });
            }
        },
        showSingleFn(txt) {
            console.log('txt', txt);
            this.showSingle = txt;
        },
        closeSingleFn() {
            this.showSingle = '';
        },
        showGuide(){
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            this.guide();
        },
        guide() {

            var steps = [];
            switch (this.businessInfo.stage){
            case 1:
                steps = [
                    {
                        element:document.querySelector('.page-status'),
                        intro:"这里是当前的任务阶段<br>"+
                            '<img src='+this.flow_image_url+'>'
                    },
                    {
                        element:document.querySelector('.workhour'),
                        intro:'这里是本次任务的消耗工时数'
                    },
                    {
                        element:document.querySelector('.aggree'),
                        intro:'您需要勾选后才能确认需求'
                    },
                    {
                        element:document.querySelector('.confirm'),
                        intro:'确认需求后进入执行阶段'
                    },
                ];
                break;
            case '2':
                console.log(222);
                break;
            case '3':
                console.log(333);
                break;
            case '4':
                console.log(444);
                break;
            default:
                console.log(555);
            }

            introJs()
                .setOptions({
                    nextLabel: '下一步',  // 下一个按钮文字
                    prevLabel: '上一步',  // 上一个按钮文字
                    skipLabel: '跳过',    // 跳过按钮文字
                    doneLabel: '完成',// 完成按钮文字
                    hidePrev: true,       // 在第一步中是否隐藏上一个按钮
                    // hideNext: true,       // 在最后一步中是否隐藏下一个按钮
                    exitOnOverlayClick: true,  // 点击叠加层时是否退出介绍
                    showStepNumbers: false,     // 是否显示红色圆圈的步骤编号
                    disableInteraction: true,   // 是否禁用与突出显示的框内的元素的交互，就是禁止点击
                    showBullets: false,         // 是否显示面板指示点
                    tooltipClass:'customTooltip',
                    steps
                })
                .onafterchange(function(target){
                    if(target.classList.contains('page-status')){
                        document.querySelector('.customTooltip').style.minWidth=window.outerWidth+'px';
                    }else {
                        document.querySelector('.customTooltip').style.minWidth='300px';
                    }
                })
                .start();
        },
        showViewerClose(){
            this.showViewer = false;
        },
        getPreview(){



            this.flow_image_url ? this.showViewer = true: this.$message.info('当前没有可预览的图片');
        }
    }
};

</script>

<style lang="sass" scoped>
    .page-content
        padding-top: 56px + 113px
        .part-main
            margin: 0px auto
            width: 920px
            background: #FFFFFF
            box-shadow: 0 2px 9px 2px #E4E4E4
            position: relative
            z-index: 10
            padding: 0 70px
            .control
                border-top: 1px dashed #C6C6C6
                padding: 30px 0 140px
                .btn
                    border: 1px solid #CDCDCD
                    border-radius: 2px
                    width: 145px
                    height: 41px
                    line-height: 40px
                    display: inline-block
                    margin-right: 50px
                    margin-top: 30px
                    text-align: center
                    font-size: 14px
                    color: #696D74
                    cursor: pointer
                    .other-icon
                        margin-top: -3px !important
                        vertical-align: middle
                    .el-dropdown
                        color: #696D74
                    .icon
                        margin-right: 10px
                    .link
                        font-size: 12px
                    &:hover,&.current
                        .el-dropdown
                            color: #204ECF
                        color: #204ECF
                        border-color: #204ECF
            .items-wrap
                padding: 28px 20px
                /deep/ svg
                    display: inline-block
                    vertical-align: middle
                    margin-top: -4px
                    margin-right: 8px
                /deep/ .item
                    margin-bottom: 50px
                /deep/ .i-title
                    margin-bottom: 50px
                    font-weight: 400
                    font-size: 26px
                    color: #4A4A4A
                    text-align: center
    .raw_image
        // display: none
        position: absolute
        top: 200
        right: 0
        margin-right: 160px
        width: 20px
        height: 20px
        opacity: 0
        .img
            width: 100px
            height:  25px
    .raw-btn
        position: relative
        text-align: center
        margin-right: 20px
        width: 85px
        float: right
        border-bottom: 1px solid #6b6b6b87
        padding-bottom: 1px
        color: #6b6b6b
        cursor: pointer
        &:hover
            color: #000000
            border-bottom: 1px solid #6b6b6b
</style>

<style lang="sass">
    .el-collection-like,
    .el-collection-dislike
        display: inline-block
        line-height: 1
        margin-top: 4px
        cursor: pointer
    .el-image-viewer__actions
        width: 190px
        .el-icon-full-screen,
        .el-icon-refresh-left,
        .el-icon-refresh-right,
        .el-image-viewer__actions__divider
            display: none
        .el-collection-like
            &.current
                color: #00A369
        .el-collection-dislike
            &.current
                color: #F95F5F
    .customTooltip
        .board
            overflow-x : scroll
        .introjs-skipbutton
            font-size: 12px
        .introjs-nextbutton
            display: inline-block
            cursor: pointer
            width: 40px
            text-align: center
            line-height: 20px
            font-size: 12px
            color: #fff
            background: #00CC83
            border-radius: 4px
            text-shadow: 0px 0px 0 #ffffff
</style>