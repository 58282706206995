<template lang="pug">
    .title-wrap
        h1
            | {{businessInfo.title}}
            span.normal(v-if="businessInfo.requirements && businessInfo.requirements.length",id="desc")
                //- | 使用场景：
                template(v-for="(item,index) in businessInfo.requirements")
                    template(v-if="item.type.id === 1")
                        | {{item.name}}
                        template(v-if="businessInfo.requirements.length < (index + 1)")
                            | 、
        .desc(v-if="businessInfo.short_description")
            pre {{businessInfo.short_description}}
            .date(v-if="businessInfo.ticket") 需求提出时间：{{businessInfo.ticket.ticket_create_date}}
</template>

<script>
export default {
    name: '',
    props: {
        businessInfo: {
            type: Object,
            default() {
                return {};
            }
        },
        stage: {
            type: Number,
            default: 0,
        }
    },
};
</script>

<style lang="sass" scoped>
    .title-wrap
        padding: 57px 0 25px
        text-align: center
        background-size: cover
        h1
            font-size: 30px
            line-height: 42px
            color: #434343
            font-weight: 400
            margin-bottom: 10px
            .normal
                padding-left: 22px
                font-size: 10px
                color: #666666
        .desc
            margin: 60px 20px 0px
            padding: 37px 65px
            background: #FFFFFF
            box-shadow: 0 0 6px 0 #EEEEEE
            border-radius: 4px
            text-align: right
            pre
                text-align: left
                font-size: 14px
                color: #696D74
                line-height: 27px
                padding-left: 57px
                background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAkCAMAAADM4ogkAAAAP1BMVEUAAACUlJSFhYWFhYWEhISEhISEhISEhISFhYWFhYWFhYWHh4eLi4uEhISEhISFhYWEhISHh4eGhoaFhYWEhIQcm3o1AAAAFHRSTlMAB/KWT6S198dzaDMQ2tKpoEY9LOLbanwAAACJSURBVDjL7dI5CsNAFATRkbVYi3fP/c9qDA1CVShVpJ/1o6EnmDLUzQ0lJ6+49ORn8SweURx3Pq68q7lb2Zy8uQTegV3vA1OynBvPZDk37ijae7xazo1PspwbU7KcG69EOTceiXJuXJtkOTeWRDk35kQ5N9pEOTdGFu0dXk3nxpdF+9z+b1lB/gOiMj83S15zhgAAAABJRU5ErkJggg==') left top no-repeat
                background-size: 20px
            .date
                padding-top: 12px
                font-size: 10px
                color: #676767
</style>