<template lang="pug">
.part-directory
    .title(v-if="businessInfo.category") {{businessInfo.category.name}} - 目录
    .anchors
        scrollactive(:modifyUrl="false",:scrollOnStart="false",:offset="180")
            a.scrollactive-item(href="#desc") 任务介绍

            //偏好风格
            template(v-for="item in businessInfo.picture_types")
                template(v-if="item.id === 1")
                    a.scrollactive-item(:href="'#picture_types_' + item.id") {{item.name}}
            template(v-for="item in businessInfo.picture_types")
                template(v-if="item.id === 2")
                    a.scrollactive-item(:href="'#picture_types_' + item.id") {{item.name}} 


            //设计素材
            template(v-for="item in businessInfo.picture_types")
                template(v-if="item.id === 3")
                    a.scrollactive-item(:href="'#picture_types_' + item.id") {{item.name}}
            template(v-for="item in businessInfo.picture_types")
                template(v-if="item.id === 4")
                    a.scrollactive-item(:href="'#picture_types_' + item.id") {{item.name}}


            template(v-for="item in businessInfo.picture_types")
                template(v-if="item.id === 10")
                    a.scrollactive-item(:href="'#picture_types_' + item.id") {{item.name}}



            //设计基础
            template(v-for="item in businessInfo.picture_types")
                template(v-if="item.id === 11")
                    a.scrollactive-item(:href="'#picture_types_' + item.id") {{item.name}}
            template(v-for="item in businessInfo.picture_types")
                template(v-if="item.id === 12")
                    a.scrollactive-item(:href="'#picture_types_' + item.id") {{item.name}}
            //文字基础

            template(v-for="item in businessInfo.picture_types")
                template(v-if="item.id === 13")
                    a.scrollactive-item(:href="'#picture_types_' + item.id") {{item.name}}



</template>

<script>
export default {
    name: '',
    props: {
        businessInfo: {
            type: Object,
            default() {
                return {};
            }
        },
        stage: {
            type: Number,
            default: 0
        }
    }
};
</script>

<style lang="sass" scoped>
    .part-directory
        padding-top: 56px + 112px + 60px
        position: fixed
        top: 0
        left: 50%
        transform: translateX(-680px)
        .title
            font-size: 16px
            color: #081120
            margin-bottom: 12px
            border-bottom: 1px solid #DEDEDE
            padding-bottom: 7px
        .anchors
            font-size: 12px
            a
                color: #696D74
                display: block
                background: #F6F7F9
                height: 40px
                line-height: 40px
                padding-left: 28px
                transition: .2s
                text-decoration: none
                &:hover,&.is-active
                    font-weight: 500
                    color: #081120

</style>