<template lang="pug">
    .items-wrap(v-if="businessInfo.text_asset")
        .item(id="text_client_like")
            .i-title 文字素材
            .cont.txt
                .p
                    pre.cont(v-html="businessInfo.text_asset")

</template>

<script>
export default {
    name: '',
    props: {
        businessInfo: {
            type: Object,
            default() {
                return {};
            }
        },
    },
};
</script>

<style lang="sass" scoped>
.txt
    background: #FFFFFF
    border: 1px solid #EAECEF
    border-radius: 4px
    font-size: 14px
    color: #696D74
    max-height: 268px
    overflow-y: scroll
    padding: 20px
    .p
        margin-bottom: 20px
        &:last-child
            margin-bottom: 0
    .sign
        font-size: 14px
        color: #1B1919
        margin-bottom: 20px
    .name
        color: #1B1919
        margin-bottom: 2px
    .cont
        color: #696D74
        line-height: 18px
</style>