<template lang="pug">
    .page-pending
        .page-header
            .inner.mod-inner
                .title {{company.name || '暂无'}}
        .page-content
            .inner.mod-inner
                router-link.back(:to="'/tasks/' + id") < 返回任务列表
                .title
                    | 工时记录
                    span.desc 剩余总工时： {{company.working_hours || 0}}
                .filter
                    strong 筛选：
                    span.type(:class="{'current': type === 0 }", @click="filterType(0)") 全部
                    span.type(:class="{'current': type === 1 }", @click="filterType(1)") 充值
                    span.type(:class="{'current': type === 2 }", @click="filterType(2)") 消耗
                .tasks
                    table
                        tr
                            th 工时记录日期
                            th 工时类型
                            th 备注
                            th 消耗工时
                            th 任务名称
                            th 查看任务
                        tr(v-for="item in tasks", :key="item.id")
                                td {{item.created_at}}
                                td {{item.type | filterStage}}
                                td {{item.desc || '暂无'}}
                                td {{item.hours}}
                                td(v-if="item.task") {{item.task.title}}

                                td(v-if="item.task")
                                    router-link.link(:to="'/' + item.task.id + '/' + (filterStage(item.task.stage))")
                                        <svg width="21" height="14" viewBox="0 0 21 14" xmlns="http://www.w3.org/2000/svg"><path d="M10.5 2.917c-.386.006-.77.067-1.139.182.17.3.262.639.264.984a2.042 2.042 0 01-2.042 2.042 2.02 2.02 0 01-.984-.264A4.073 4.073 0 1010.5 2.917zm10.373 3.55C18.896 2.61 14.982 0 10.5 0 6.018 0 2.103 2.612.127 6.468c-.17.335-.17.73 0 1.064C2.104 11.39 6.018 14 10.5 14c4.482 0 8.397-2.612 10.373-6.468.17-.335.17-.73 0-1.064zM10.5 12.25c-3.597 0-6.894-2.005-8.675-5.25C3.606 3.755 6.903 1.75 10.5 1.75c3.597 0 6.894 2.005 8.675 5.25-1.78 3.245-5.078 5.25-8.675 5.25z" fill="currentColor" fill-rule="nonzero"/></svg>
                                td(v-else)
                    el-pagination(
                        layout="prev, pager, next"
                        :total="tasksTotal"
                        background
                        @current-change="sizeChange"
                    )
</template>

<script>
import { Message } from 'element-ui';
import Api from '../../common/api';

export default {
    name: 'Index',
    data() {
        return {
            stage: 0,
            id: '',
            tasks: [],
            tasksTotal: 0,
            company: {},
            type: 0,
            page: 1,
        };
    },
    filters: {
        filterStage(num) {
            const STAGE = [0, '充值', '消耗'];
            return STAGE[num];
        }
    },
    created() {
        this.init();
    },
    methods:{
        filterStage(num) {
            const STAGE = [0, 'pending', 'executing', 'confirming', 'finished'];
            return STAGE[num];
        },
        init() {
            const { id } = this.$route.params;
            if (id) {
                this.id = id;
                const loading = this.$loading({
                    text: '获取信息中...',
                });
                Api.get(`/api/companies/${id}`).then(data => {
                    this.company = data;
                });
                Api.get(`/api/companies/${id}/workhourRecords`, { type: this.type, page: this.page }).then(data => {
                    this.tasks = data.data;
                    this.tasksTotal = data.total;
                    setTimeout(() => {
                        loading.close();
                    }, 500);
                }).catch(() => {
                    loading.close();
                });
            } else {
                Message({
                    message: '地址错误，请联系管理员！',
                    type: 'error'
                });
            }
        },
        chageStage(num) {
            this.stage = num;
            this.init();
        },
        filterType(num) {
            this.type = num;
            this.init();
        },
        sizeChange(num) {
            this.page = num;
            this.init();
        }
    }
};
</script>

<style lang="sass" scoped>
    .page-content
        padding-top: 70px
        .back
            display: block
            margin-bottom: 20px
            font-size: 12px
            color: #696D74
            text-decoration: none
        .title
            font-size: 24px
            color: #0E0E0E
            margin-bottom: 16px
            .desc
                font-size: 12px
                color: #696D74
                padding-left: 18px
        .filter
            background: #FFFFFF
            border: 1px solid #DCDCDC
            box-shadow: 0 2px 4px 0 rgba(194,194,194,0.50)
            border-radius: 2px
            padding: 15px 30px
            font-size: 12px
            color: #696D74
            margin-bottom: 16px
            .type
                margin-left: 28px
                cursor: pointer
            .current
                color: #204ECF
        .tasks
            .el-pagination
                display: flex
                padding-top: 25px
                justify-content: center
        table
            width: 100%
            background: #FFFFFF
            border: 1px solid #DCDCDC
            box-shadow: 0 2px 4px 0 rgba(194,194,194,0.50)
            border-radius: 2px
            tr:hover
                background-color: #EDEDED
            th
                height: 67px
                background: #EDEDED
                border-radius: 2px
                text-align: center
                font-size: 16px
                color: #0E0E0E
                font-weight: normal
            td
                text-align: center
                height: 60px
                border-bottom: 1px solid #DADADA
                .link
                    color: #AEAEAE
                    &:hover
                        color: #204ECF

</style>