<template lang="pug">
    .items-wrap(v-if="businessInfo.ticket && businessInfo.ticket.client_like_file_desc")
        .item(id="client_like_file_desc")
            .cont.txt
                .p
                    pre.name {{businessInfo.ticket.client_like_file_desc}}

</template>

<script>
export default {
    name: '',
    props: {
        businessInfo: {
            type: Object,
            default() {
                return {};
            }
        },
        stage: {
            type: Number,
            default: 0
        }
    }
};
</script>

<style lang="sass" scoped>

#client_like_file_desc
    margin-top: -50px

.txt
    margin-top: -100px
    background: #FFFFFF
    border: 1px solid #EAECEF
    border-radius: 4px
    font-size: 14px
    color: #696D74
    max-height: 268px
    overflow-y: scroll
    padding: 20px
    .p
        margin-bottom: 20px
        &:last-child
            margin-bottom: 0
    .sign
        font-size: 14px
        color: #1B1919
        margin-bottom: 20px
    .name
        color: #1B1919
        margin-bottom: 2px
    .cont
        color: #696D74
        line-height: 18px
</style>