<template lang="pug">
    .items-wrap
        .item(id="other-req", v-if="businessInfo.text_web_asset")
            .i-title 规格要求
        .other-req(v-if="businessInfo.requirements && businessInfo.requirements.length")
            template(v-for="(item,index) in businessInfo.requirements")
                    template(v-if="item.type.id !== 1")
                        .list
                            .desc {{item.type.name}}
                            .cont(:class="'color' + index") {{item.name}}
</template>

<script>
export default {
    name: '',
    props: {
        businessInfo: {
            type: Object,
            default() {
                return {};
            }
        }
    },
};
</script>

<style lang="sass" scoped>
    .normal
        padding: 30px
        font-size: 14px
        color: #696D74
        max-height: 268px
        overflow-y: scroll
        background: #FCFCFC
        border: 1px solid #EAECEF
        box-shadow: 0 1px 3px 0 #EBEBEB
        border-radius: 4px
        line-height: 20px
    .other-req
        color: #222
        padding: 20px 0px 140px
        display: flex
        justify-content: space-around
        .list
            text-align: center
            background: #FFFFFF
            border: 1px solid #F7F7F7
            box-shadow: 0 2px 2px 0 rgba(235,235,235,0.50)
            border-radius: 7px
            width: 144px
            height: 93px
            display: flex
            justify-content: center
            flex-direction: column
            .desc
                font-size: 12px
                color: #696D74
                margin-bottom: 10px
            .cont
                font-size: 14px
                color: #1B1919
                font-weight: 500
</style>