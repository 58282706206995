import { get, put } from './request.js';

export default {
    get, put,
    // 需求确认阶段
    fetchPendingInfo: (id = 2049) => get(`/api/tasks/${id}/pending`),
    confirmPending: (id = 2049) => put(`/api/tasks/${id}/pended`),
    confirmCollection: (id = 2049, params = {}) => put(`api/taskPictures/${id}/collection`, params),

    // 执行阶段
    fetchExecutingInfo: (id = 2049) => put(`/api/tasks/${id}/executing`),

    // 交付阶段
    fetchConfirmingInfo: (id = 2049) => put(`/api/tasks/${id}/confirming`),
    confirmed: (id = 2049) => put(`/api/tasks/${id}/confirmed`),

    // 任务完成阶段
    fetchTalentsInfo: (id= 0) => put(`/api/tasks/${id}/finished`),
};
