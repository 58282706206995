<template lang="pug">
    article.page-wrapper(:class="{'mobile-page': isMobile }")
        router-view
        .page-footer
            <svg width="112" height="14" viewBox="0 0 112 14" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M36.499.437V14H31V2.087c0-.91.743-1.65 1.656-1.65h3.843zm16.262 0V14h-5.5V2.087c0-.91.743-1.65 1.656-1.65h3.844zm8.683 11.914V14h-7.83v-1.65h7.83zM45.65.437v1.65l-1.912-.001v9.44A2.481 2.481 0 0141.255 14h-3.182v-1.65h3.182c.457 0 .828-.37.828-.824l-.001-9.44h-4.894V.438h8.462zm-10.806 1.65h-2.188V12.35h2.188V2.087zm16.262 0h-2.19V12.35h2.19V2.087zM61.444 9.03v1.65h-7.83V9.03h7.83zM55.134 0v.775h6.348v1.886c0 1.175-.534 2.113-1.407 2.832.696.109 1.402.193 1.925.193v1.65c-1.281 0-3.36-.407-4.316-.61-1.28.412-2.76.61-4.235.61v-1.65c1.464 0 2.937-.222 4.097-.657l.004-.02.028.006c1.336-.505 2.248-1.295 2.248-2.354v-.323h-6.348V0h1.655z" fill="#CBCBCB"/><text font-family="Helvetica" font-size="12" fill="#CBCBCB"><tspan x="68.326" y="11">Pro</tspan></text><path stroke="#CBCBCB" stroke-linecap="square" d="M.5 7.5h20M91.5 7.5h20"/></g></svg>
</template>

<script>
export default {
    name: 'App',
    data() {
        return {
            isMobile: (/Android|webOS|iPhone|iPod|BlackBerry/i.test(window.navigator.userAgent)),
        };
    },
    mounted(){
    }
};
</script>

<style lang="sass">
    @import "./common/style/global.scss"
    body, h1, h2, h3, ul, ol, li, p, div, section, article
        display: block
        margin: 0
        padding: 0
    ::-webkit-scrollbar
        display: none
    body
        font-family: "SF Pro SC","SF Pro Text","SF Pro Icons","PingFang SC","Helvetica Neue","Helvetica","Arial",sans-serif
        background-color: #F7F7F7
    .mod-inner
        width: 1400px
        margin: 0 auto
    img
        max-width: 100%
    pre
        white-space: pre-wrap
        word-wrap: break-word
    .el-dropdown-menu
        .link
            font-size: 12px
            color: #696D74
            display: block
            text-decoration: none
        .icon
            vertical-align: middle
            display: inline-block
            margin-top: -2px
            margin-right: 5px
    .page-header
        background: #FFFFFF
        color: #1B1919
        height: 56px
        position: fixed
        width: 100%
        z-index: 1000
        .inner
            height: 100%
            display: flex
            align-items: center
            justify-content: space-between
            .title
                font-size: 16px
                color: #081120
                font-weight: 500
            .link
                cursor: pointer
                font-size: 12px
                .sign
                    text-align: center
                    line-height: 28px
                    display: inline-block
                    width: 37px
                    height: 28px
                    background: #FFFFFF
                    border: 1px solid #F5F5F5
                    box-shadow: 0 1px 3px 0 rgba(232,232,232,0.50)
                    border-radius: 2px
    .page-footer
        padding-top: 33px
        text-align: center
        padding-bottom: 200px
    .mobile-page
        .page-header
            padding: 0 20px
        .page-content
            display: flex
            .inner
                display: flex
                width: auto
            .part-directory
                padding-left: 20px
                min-width: 200px
            .part-directory,
            .part-status,
                position: relative !important
                margin-top: -200px !important
                transform: none !important
                left: auto
                top: auto
            .part-main
                flex: 1
                padding-top: 100px !important
                transform: none !important
                position: static !important
            .part-status
                min-width: 200px
                .btn
                    width: 100% !important
</style>